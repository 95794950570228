import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import {
  Box,
  Typography,
  Grid,
  GridProps,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField
} from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import ClearIcon from '@mui/icons-material/Clear';
import config from '@configFile';
import { useGetLatestDeals } from '@hooks/useDeals';
import { useGetCouponDetails } from '@hooks/useCoupons';
import useLocalStorage from '@hooks/useLocalStorage';
import { useGetUserData } from '@hooks/useGetUserData';
import trackUse from '@utils/trackUse';
import LazyLoadedComponent from '@components/LazyLoadedComponent';
import MailingListMessage from '@components/MailingListMessage';
import Disclaimer from '@components/Disclaimer';
import Loading from '@components/Loading';
import { logPostHogEvent } from '@utils/index';
import CouponCardSkeleton from './components/CouponCardSkeleton';
import CouponList from './components/CouponList';
import {
  getAllDealsToRender,
  getLatestDealsWithPromoCodeGroupedOrdered,
  getLatestDealsByPromoCodeGrouped
} from './utils';

/* eslint-disable react/no-unused-prop-types */
interface CouponListsProps {
  gridItemProps?: GridProps;
  max?: number;
  singleCode?: string;
  tag?: string;
  openExpanded?: boolean;
  noCard?: boolean;
  showMasonry?: boolean;
  showNoCouponFoundMessage?: boolean;
  noDisclaimer?: boolean;
}
/* eslint-enable react/no-unused-prop-types */

const CouponLists = ({
  max = 5,
  singleCode,
  tag = config.AFFILIATE_TAGS.COUPON_PAGE,
  openExpanded = true,
  noCard = false,
  noDisclaimer = false,
  gridItemProps = {
    sm: 12
  },
  showMasonry = false,
  showNoCouponFoundMessage = false
}: CouponListsProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFilterDebounced, setSearchFilterDebounced] = useState('');
  const [isFiltering, setIsFiltering] = useState(false);
  const [limit, setLimit] = useState(showMasonry ? 2000 : 5);
  const { data: dealsData, isLoading } = useGetLatestDeals();
  const latestDeals = dealsData?.latestDeals;
  const couponRefElement = useRef(null);
  const limitRef = useRef(limit);
  const { data: couponDetails, isLoading: isLoadingCouponDetails } =
    useGetCouponDetails();
  const [orderBy, setOrderBy] = useLocalStorage(
    'numPromoCodeDeals',
    'dateActive'
  );

  const { data: user } = useGetUserData();

  const debounceUpdateFilteredItems = React.useCallback(
    _.debounce((value) => {
      setSearchFilterDebounced(value);
      setIsFiltering(false);

      trackUse({
        item: 'coupon-list-search',
        value,
        type: 'ACTION'
      });

      logPostHogEvent('coupon-list-search', {
        value,
        type: 'ACTION'
      });
    }, 1000),
    []
  );

  useEffect(() => {
    limitRef.current = limit;
  }, [limit]);

  const onWindowScroll = _.debounce(() => {
    if (!couponRefElement.current) return;
    if (!limitRef.current) return;
    if (showMasonry) return;
    const offset = 600;
    const { top } = couponRefElement.current.getBoundingClientRect();
    const shouldLoadMore =
      top + offset >= 0 && top - offset <= window.innerHeight;

    if (shouldLoadMore) {
      setLimit(limitRef.current + 10);
    }
  }, 50);

  useEffect(() => {
    let intervalId: number | null = null;

    const startInterval = () => {
      if (intervalId === null) {
        intervalId = window.setInterval(() => {
          onWindowScroll(); // Directly call your debounced function here
        }, 250); // Every quarter second
      }
    };

    const clearScrollInterval = () => {
      if (intervalId !== null) {
        clearInterval(intervalId);
        intervalId = null;
      }
    };

    const handleScrollStart = () => {
      startInterval();
    };

    const handleScrollEnd = _.debounce(() => {
      clearScrollInterval();
    }, 250); // Assuming scroll stops when no scroll events have been detected for 250ms

    window.addEventListener('scroll', handleScrollStart);
    window.addEventListener('scroll', handleScrollEnd);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScrollStart);
      window.removeEventListener('scroll', handleScrollEnd);
      clearScrollInterval(); // Ensure interval is cleared when component unmounts
    };
  }, []); // Empty dependencies array means this effect runs once on mount and cleanup on unmount

  const latestDealsWithPromoCode = (latestDeals || [])?.filter(
    (latestDeal) => latestDeal.promoCode
  );

  // group by promoCode
  const latestDealsWithPromoCodeGrouped = getLatestDealsByPromoCodeGrouped(
    latestDealsWithPromoCode
  );

  // order latestDealsWithPromoCodeGrouped by number of items descending
  const latestDealsWithPromoCodeGroupedOrdered =
    getLatestDealsWithPromoCodeGroupedOrdered({
      latestDealsWithPromoCodeGrouped,
      orderBy,
      singleCode,
      couponDetails,
      user
    });

  const allDealsToRender = getAllDealsToRender(
    latestDealsWithPromoCodeGroupedOrdered,
    searchFilterDebounced
  );
  const allDealsToRenderLimited = allDealsToRender.slice(0, limit);

  const renderOrderCouponListSelect = () => {
    return (
      <Box
        sx={{
          display: 'inline',
          justifyContent: 'flex-end',
          marginBottom: showMasonry ? '10px' : '0px'
        }}
      >
        <FormControl size="small">
          <InputLabel
            id="sort-small-label"
            sx={{
              backgroundColor: '#fff',
              padding: '0 4px'
            }}
          >
            Sort By
          </InputLabel>
          <Select
            labelId="sort-small-label"
            value={orderBy}
            onChange={(e) => {
              setOrderBy(e.target.value);
              trackUse({
                item: 'coupon-list-order-by',
                value: e.target.value,
                type: 'ACTION'
              });

              logPostHogEvent('coupon-list-order-by', {
                value: e.target.value,
                type: 'ACTION'
              });
            }}
            size="small"
            sx={{
              marginLeft: '8px'
            }}
          >
            <MenuItem dense value="dateActive">
              Newest Coupon Deals
            </MenuItem>
            <MenuItem dense value="highestDiscount">
              Highest Discount
            </MenuItem>
            <MenuItem dense value="numPromoCodeDeals">
              Number of Deals
            </MenuItem>
            <MenuItem dense value="cheapestdeals">
              Lowest Price Deals
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  };

  const renderCouponSearch = () => {
    return (
      <Box
        sx={{
          display: 'inline',
          // justifyContent: 'flex-end',
          marginBottom: showMasonry ? '10px' : '0px'
        }}
      >
        <TextField
          size="small"
          label="Search Coupons"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setIsFiltering(true);
            if (e.target.value !== '') {
              setIsFiltering(true);
              debounceUpdateFilteredItems(e.target.value);
            } else {
              setIsFiltering(false);
              setSearchFilterDebounced('');
            }
          }}
          // render icon to x out input
          InputProps={{
            endAdornment: (
              <Box
                sx={{
                  // display: 'flex',
                  // alignItems: 'center',
                  // justifyContent: 'center',
                  marginRight: '8px'
                }}
              >
                {!isFiltering && searchTerm !== '' && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSearchTerm('');
                      setIsFiltering(false);
                      setSearchFilterDebounced('');
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </Box>
            )
          }}
        />
      </Box>
    );
  };

  const renderSkeletons = (numSkeletons: number) => {
    return (
      <>
        {_.times(numSkeletons, (i) => (
          <CouponCardSkeleton key={i} />
        ))}
      </>
    );
  };

  if (singleCode) {
    // only show the deals of this specific code
    const codeDeals = latestDealsWithPromoCodeGroupedOrdered.filter(
      (promoCodeGroup) => promoCodeGroup.promoCode === singleCode
    )?.[0];

    if (isLoading || isLoadingCouponDetails) {
      return <>{renderSkeletons(1)}</>;
    }

    if (!codeDeals) {
      if (showNoCouponFoundMessage) {
        return (
          <Box
            display="flex"
            justifyContent="center"
            textAlign="center"
            margin="16px"
          >
            <Typography variant="subtitle1">
              No deals found for this coupon code - what you were looking at may
              have expired! Close this modal to see the latest deals.
            </Typography>
          </Box>
        );
      }

      return null;
    }

    return (
      <CouponList
        promoCodeGroup={codeDeals}
        gridItemProps={gridItemProps}
        max={max}
        key={codeDeals.promoCode}
        tag={tag}
        openExpanded={openExpanded}
        noCard={noCard}
      />
    );
  }

  return (
    <>
      <Box
        sx={{
          display: 'block'
        }}
      >
        <Box
          sx={{
            flexGrow: 3,
            display: 'block'
          }}
        >
          <Box>
            {!showMasonry ? (
              <Typography component="h3" variant="h5" gutterBottom>
                Most Popular Amazon Coupon Deals
              </Typography>
            ) : null}

            {!showMasonry && !noDisclaimer ? <Disclaimer /> : null}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            maxWidth: '100%',
            paddingBottom: '8px'
          }}
          justifyContent="flex-end"
        >
          {' '}
          {renderCouponSearch()}
          {renderOrderCouponListSelect()}
        </Box>
      </Box>
      <MailingListMessage />
      {showMasonry ? (
        <Box marginLeft="-16px" marginRight="-16px">
          <Masonry
            sx={{
              backgroundColor: '#80808029',
              margin: '0px !important'
            }}
            columns={{ xs: 1, md: 2 }}
            spacing={2}
            defaultHeight={240}
            defaultColumns={1}
            defaultSpacing={2}
          >
            {(isLoading || isFiltering) && renderSkeletons(limit)}
            {!isFiltering && !isLoading && allDealsToRender.length === 0 && (
              <Box
                display="flex"
                justifyContent="center"
                textAlign="center"
                margin="16px"
                sx={{
                  width: '100%',
                  backgroundColor: '#80808029'
                }}
              >
                <Typography variant="subtitle1">
                  No deals found for this search term!
                </Typography>
              </Box>
            )}
            {(allDealsToRenderLimited || []).map((promoCodeGroup) => {
              return (
                <CouponList
                  promoCodeGroup={promoCodeGroup}
                  gridItemProps={gridItemProps}
                  max={max}
                  key={promoCodeGroup.promoCode}
                />
              );
            })}
          </Masonry>
        </Box>
      ) : (
        <Grid
          sx={{
            marginTop: '10px'
          }}
          container
          spacing={1}
        >
          {isLoading && renderSkeletons(limit)}
          {(allDealsToRenderLimited || []).map((promoCodeGroup) => {
            return (
              <CouponList
                promoCodeGroup={promoCodeGroup}
                gridItemProps={gridItemProps}
                max={max}
                key={promoCodeGroup.promoCode}
              />
            );
          })}
          {limit < allDealsToRender.length ? (
            <Box
              ref={couponRefElement}
              margin="12px 0"
              display="flex"
              justifyContent="center"
              width="100%"
            >
              <Loading />
            </Box>
          ) : null}
        </Grid>
      )}
    </>
  );
};

export const LazyLoadedCouponLists = (props: CouponListsProps) => {
  return <LazyLoadedComponent Component={CouponLists} componentProps={props} />;
};

export default CouponLists;
