import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  chip: {
    marginRight: '4px !important'
  },
  chipSS: {
    // backgroundColor: '#D14900 !important',
    backgroundColor: '#00796b !important',
    color: 'white !important',
    marginTop: '0.35em !important'
  },
  chipSSIcon: {
    color: 'white !important',
    fontSize: '13px !important',
    marginLeft: '6px !important'
  }
}));
