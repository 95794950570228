import _ from 'lodash';
import { DealPostType } from '../types';

export const sortDeals = (deals: DealPostType[], sortOrder: string) => {
  let sortedItems = [...deals];

  const sortByFinalPrice = (order: 'asc' | 'desc') =>
    _.orderBy(sortedItems, ['finalPrice', 'ASIN'], [order, 'asc']);

  const sortByDiscount = (
    order: 'asc' | 'desc',
    discountFunc: (item: DealPostType) => number
  ) => _.orderBy(sortedItems, [discountFunc, 'ASIN'], [order, 'asc']);

  switch (sortOrder) {
    case 'lowestPrice':
    case 'lowest':
    case 'finalPrice':
      return sortByFinalPrice('asc');
    case 'highest':
    case 'highestPrice':
      return sortByFinalPrice('desc');
    case 'highest-discount':
    case 'highestDiscount':
    case 'largest-discount':
      return sortByDiscount('desc', getPercentageDiscountFromListPrice);
    case 'lowestDiscount':
      return sortByDiscount('asc', getPercentageDiscountFromListPrice);
    case 'highest-coupon-discount':
      return sortByDiscount('desc', getCouponDiscountPercentage);
    case 'highest-discount-non-list-price':
      return sortByDiscount('desc', getOverAllPercentageDiscount);
    case 'mostPopular':
    case 'highest-selling':
    case 'best-selling':
      sortedItems = _.orderBy(
        sortedItems,
        [(p) => p.totalSold || 0, 'ASIN'],
        ['desc', 'asc']
      );
      break;
    case 'best-selling-by-sales-items':
      sortedItems = _.orderBy(
        sortedItems,
        [(p) => p.totalSalesItems || 0, 'ASIN'],
        ['desc', 'asc']
      );
      break;

    case 'last-sold-first':
      sortedItems = _.orderBy(
        sortedItems,
        [
          (deal) => deal.dateLastSold === null, // false (0) for dates, true (1) for nulls
          'dateLastSold',
          'ASIN'
        ],
        ['asc', 'asc', 'asc']
      );
      break;
    case 'newest-sold-first':
      sortedItems = _.orderBy(
        sortedItems,
        [
          (deal) => deal.dateLastSold === null, // false (0) for dates, true (1) for nulls
          'dateLastSold',
          'ASIN'
        ],
        ['asc', 'desc', 'asc']
      );
      break;
    case 'lowest-average-comparison':
      sortedItems = _.orderBy(
        sortedItems,
        [
          (p) => {
            if (
              p.averagePrice &&
              p.averagePrice > p.finalPrice &&
              p.totalSold > 4
            ) {
              return ((p.averagePrice - p.finalPrice) / p.averagePrice) * 100;
            }
            return 0;
          },
          'ASIN'
        ],
        ['desc', 'asc']
      );
      break;
    case 'newest-added-first':
      sortedItems = _.orderBy(
        sortedItems,
        ['dateAdded', 'ASIN'],
        ['desc', 'asc']
      );
      break;
    case 'oldest':
      sortedItems = _.orderBy(
        sortedItems,
        ['dateAdded', 'ASIN'],
        ['asc', 'asc']
      );
      break;
    case 'dateActive':
    case 'newest':
    default:
      sortedItems.sort(
        (a, b) =>
          new Date(b.dateFirstActive).getTime() -
          new Date(a.dateFirstActive).getTime()
      );
      break;
  }

  return sortedItems;
};

const getCouponDiscountPercentage = (p: DealPostType) => {
  if (p.couponPercentage) {
    return p.couponPercentage;
  }

  if (p.couponFixed && p.currentPrice && p.currentPrice !== 0) {
    // convert fixed coupon to percentage
    return (p.couponFixed / p.currentPrice) * 100;
  }

  return 0;
};

const getSSPercentage = (p: DealPostType) => {
  if (p.ss && p.maxSs) {
    return p.maxSs;
  }
  return 0;
};

const getPercentageOfFixedDiscount = (p: DealPostType) => {
  if (p.promoFixed && p.currentPrice && p.currentPrice > 0) {
    return (p.promoFixed / p.currentPrice) * 100;
  }
  return 0;
};

export const getOverAllPercentageDiscount = (post: DealPostType) => {
  return (
    getSSPercentage(post) +
    getCouponDiscountPercentage(post) +
    getPercentageOfFixedDiscount(post)
  );
};

export const getPercentageDiscountFromListPrice = (
  post: DealPostType
): number | null => {
  // this should be finalPrice / listPrice
  if (post.listPrice && post.listPrice !== 0) {
    const percentage =
      ((post.listPrice - post.finalPrice) / post.listPrice) * 100;
    // percentage as a round number
    return Math.round(percentage);
  }
  return getOverAllPercentageDiscount(post);
  // return
};

export const getEarliestLatestDealsDateActive = (
  latestDeals: DealPostType[]
) => {
  const latestDealsActiveDates = latestDeals.map((latestDeal) => {
    return latestDeal?.postDate
      ? new Date(latestDeal?.postDate)
      : new Date(latestDeal?.dateActive);
  });

  return latestDealsActiveDates.sort((a, b) => {
    return a.getTime() - b.getTime();
  })[0];
};

export const findHighestPercentageDiscount = (
  dealListItems: DealPostType[]
): number | null => {
  const hpd = dealListItems.reduce((highestDiscount, dealItem) => {
    const discount = getOverAllPercentageDiscount(dealItem);
    return discount > highestDiscount && discount < 100
      ? discount
      : highestDiscount;
  }, 0);

  return hpd && hpd > 0 && hpd < 100 ? Math.ceil(hpd) : null;
};
