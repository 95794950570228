import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CardContent, Card, Typography, ListItem, List } from '@mui/material';
import Accordion from '@components/Accordion';
import config from '@configFile';
import { scrollToHash } from '@utils/index';
import { MonetizingLink } from '..';

interface FaqItem {
  question: string;
  answer: React.ReactNode;
}

const FaqAccordion: React.FC = () => {
  const bodyVariant = 'body1';

  const faqs: FaqItem[] = [
    {
      question: 'What is this subscription program?',
      answer: (
        <>
          <Typography variant={bodyVariant} gutterBottom>
            Subscribe & Save is Amazon&apos;s subscription program where once a
            month you can get a box of items of your choice for up to an extra
            15% off Amazon&apos;s already low prices. Save on time, money,
            hassle and gas while keeping your household stocked up!
          </Typography>

          <Typography variant={bodyVariant} gutterBottom>
            Some benefits of using Subscribe & Save for Amazon Prime members
            include:
          </Typography>

          <List
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              display: 'inline-block'
            }}
          >
            <ListItem sx={{ display: 'block' }}>
              <strong>Discounts:</strong> You can save up to 15% on your
              subscribed items, and the more items you subscribe to, the higher
              the discount.
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <strong>Convenience:</strong> You don&apos;t have to worry about
              running out of your frequently purchased items because they will
              be delivered to you automatically.
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <strong>Flexibility:</strong> You can change or cancel your
              subscriptions at any time.
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <strong>Free Shipping:</strong> Your subscribed items will be
              delivered to you with free shipping.
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <strong>No commitment:</strong> You can skip a delivery or cancel
              your subscription at any time without penalty.
            </ListItem>
          </List>
        </>
      )
    },
    {
      question: 'Can I cancel at any time?',
      answer:
        "Absolutely! Amazon will even send you an email up to 3 days before your order ships so you can cancel any renewing subscriptions if you'd like! A great tip is to select your subscription to ship every 6 months, giving you ample time to cancel."
    },
    {
      question: 'How do I cancel my Amazon Subscribe & Save subscriptions?',
      answer: (
        <Typography variant={bodyVariant} gutterBottom>
          <MonetizingLink
            href={`https://www.amazon.com/gp/subscribe-and-save/manager/viewsubscriptions?&linkCode=ll2&tag=${config.AFFILIATE_TAGS.SUB_SAVE}&linkId=4ebad80a51c1fe877a92907507ae77c5&language=en_US&ref_=as_li_ss_tl`}
            target="_blank"
            tag={config.AFFILIATE_TAGS.SUB_SAVE}
            item="manage-ss-click"
            underline="always"
            clickType={`https://www.amazon.com/gp/subscribe-and-save/manager/viewsubscriptions?&linkCode=ll2&tag=${config.AFFILIATE_TAGS.SUB_SAVE}&linkId=4ebad80a51c1fe877a92907507ae77c5&language=en_US&ref_=as_li_ss_tl`}
          >
            Go to this page
          </MonetizingLink>
          , select the item you want to cancel and click cancel subscription. We
          recommend choosing the option &quot;this is too expensive&quot; so it
          encourages Amazon to give us more low prices.
        </Typography>
      )
    },
    {
      question: 'Will my Amazon subscription renew at the same price?',
      answer:
        "Unfortunately, no. As we feature many of Amazon's best Subscribe & Save deals, usually with one-time use coupons, any future deliveries will renew at the current price at the time of shipment, including any Subscribe & Save discounts. This is why many people choose to delete subscriptions after they have shipped. Setting your subscription to 6 months maximizes your time to cancel, especially if your ordering habits are price-based."
    },
    {
      question:
        "Why isn't my Subscribe & Save page showing the prices I agreed to pay?",
      answer:
        'To see the price you agreed to, click into your orders and find the specific Subscribe & Save item. The discounts should be listed in the invoice. Currently, discounts are not shown on the Subscribe & Save page. Rest assured, you will never be charged more than what you agree to for the first order, but future deliveries might cost more.'
    },
    {
      question:
        "How do I move my Subscribe & Save order if I'll be on vacation?",
      answer:
        "To move your delivery date, go to the Subscribe & Save page and click 'Change delivery date.' You might even be able to move your delivery date up to receive your items faster! It's possible to receive more than one Subscribe & Save box per month. Reasons to move or skip a delivery include being fully stocked or away from home."
    },
    {
      question: 'Is the Subscribe & Save discount the same for every item?',
      answer:
        "Actually, no. The Subscribe & Save discount varies. Check the discount box before checkout. With 5 or more items shipping in a month, you'll get a higher discount, typically 5%-20%. Fewer items will yield a lower discount, around 0%-5%."
    },
    {
      question: 'Can I save money by ordering 4 more Subscribe & Save items?',
      answer:
        'Absolutely, there are situations where adding one more item can increase your total subscription discount, outweighing the cost of the 5th item, thus saving you money.'
    }
  ];

  return <Accordion items={faqs} />;
};

const SubscribeSaveExplanation = ({
  shouldRenderTopSpace = true
}: {
  shouldRenderTopSpace?: boolean;
}) => {
  const location = useLocation();

  useEffect(() => {
    // Check if the URL contains an anchor link
    if (location.hash) {
      scrollToHash(location.hash);
    }
  }, [location]);

  return (
    <Card
      style={{
        margin: shouldRenderTopSpace
          ? '1rem 0rem 1rem 1rem'
          : '0px 0rem 1rem 1rem'
      }}
    >
      <CardContent>
        <div id="subscribe-save-tips" />
        <Typography variant="h6" component="h2" gutterBottom>
          Everything you need to know about Amazon Subscribe & Save...
        </Typography>
        <FaqAccordion />
      </CardContent>
    </Card>
  );
};

export default SubscribeSaveExplanation;
