import React from 'react';
import { Box, Typography } from '@mui/material';
import { useGetTopDeals } from '@hooks/useTopDeals';
import { useGetPromotions } from '@hooks/usePromotions';

import { DealPostType } from '@types';
import { useGetFavorites } from '@hooks/useFavorites';
import {
  getRibbonType,
  getRibbonColor,
  getRibbonDisplayName,
  renderRibbonIcon
} from './utils';

export const CarouselRibbon = ({
  dealSummary,
  top = '0px',
  onlyShowImportant = false,
  opacity = 0.8,
  padding = '4px 8px',
  fontSize = '0.75rem',
  left = '0'
}: {
  dealSummary: DealPostType | null;
  top?: string;
  onlyShowImportant?: boolean;
  opacity?: number;
  padding?: string;
  fontSize?: string;
  left?: string;
}) => {
  const { data: promotions } = useGetPromotions();
  const { data: topDealsData } = useGetTopDeals();
  const { data: userFavorites } = useGetFavorites();

  const topDeals = topDealsData || [];

  const ASIN = dealSummary?.ASIN;

  const isFavorite = React.useMemo(() => {
    return ASIN && userFavorites?.length > 0
      ? (userFavorites || []).includes(ASIN)
      : false;
  }, [ASIN, userFavorites]);

  if (!dealSummary) {
    return null;
  }

  const currentPromotionLinks = (promotions || []).map((promotion) => {
    return promotion.extraDealLink;
  });

  const ribbonType = getRibbonType(
    dealSummary,
    currentPromotionLinks,
    topDeals,
    isFavorite,
    onlyShowImportant
  );
  const ribbonColor = getRibbonColor(ribbonType);
  const ribbonDisplayName = getRibbonDisplayName(ribbonType);

  if (ribbonType === 'NO_RIBBON') {
    return null;
  }

  const baseStyles = {
    position: 'absolute',
    left,
    background: ribbonColor,
    color: 'white',
    padding,
    fontWeight: 'bold',
    zIndex: 1,
    top,
    opacity,
    display: 'flex'
  };

  const renderInnerRibbonContent = () => {
    return (
      <>
        {renderRibbonIcon(ribbonType, false)}
        <Typography
          variant="caption"
          sx={{
            textTransform: 'uppercase',
            display: 'inline',
            fontSize
          }}
        >
          {ribbonDisplayName}
        </Typography>
      </>
    );
  };

  return <Box sx={baseStyles}>{renderInnerRibbonContent()}</Box>;
};

const Ribbon = ({
  dealSummary,
  top = '8px'
}: {
  dealSummary: DealPostType | null;
  top?: string;
}) => {
  const { data: promotions } = useGetPromotions();
  const { data: topDealsData } = useGetTopDeals();
  const topDeals = topDealsData || [];

  if (!dealSummary) {
    return null;
  }

  const currentPromotionLinks = (promotions || []).map((promotion) => {
    return promotion.extraDealLink;
  });

  const ribbonType = getRibbonType(
    dealSummary,
    currentPromotionLinks,
    topDeals
  );
  const ribbonColor = getRibbonColor(ribbonType);
  const ribbonDisplayName = getRibbonDisplayName(ribbonType);

  if (ribbonType === 'NO_RIBBON') {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        top,
        left: '-10px',
        height: '40px',
        lineHeight: '40px',
        background: ribbonColor,
        padding: '0 25px 10px 10px',
        clipPath: `polygon(
                100% 0,
                0 0,
                0 calc(100% - 10px),
                calc(0% + 10px) 100%,
                calc(0% + 10px) calc(100% - 10px),
                100% calc(100% - 10px),
                calc(100% - 15px) calc(50% - 10px/2)
            )`,
        boxShadow: '0 calc(-1*10px) 0 inset #0005'
      }}
    >
      {renderRibbonIcon(ribbonType, true)}
      <Typography
        variant="button"
        sx={{
          color: '#FFF',
          paddingLeft: '10px',
          paddingRight: '16px'
        }}
      >
        {ribbonDisplayName}
      </Typography>
    </Box>
  );
};

export default Ribbon;
