import React from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import SellIcon from '@mui/icons-material/Sell';
import CampaignIcon from '@mui/icons-material/Campaign';
import InsightsIcon from '@mui/icons-material/Insights';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import BlockIcon from '@mui/icons-material/Block';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CelebrationIcon from '@mui/icons-material/Celebration';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

import { DealPostType } from '@types';
import colors from '@utils/colors';
import { getCurrentDealEventStrObj } from '@utils/index';

const currentEventObj = getCurrentDealEventStrObj();

const largeStyle = {
  color: '#FFF',
  verticalAlign: 'middle',
  marginBottom: '2px',
  marginLeft: '8px'
};

const smallStyle = {
  color: '#FFF',
  verticalAlign: 'middle',
  marginBottom: '2px',
  marginRight: '8px',
  fontSize: '1rem'
};

type RibbonType =
  | 'SUBSCRIBE_SAVE'
  | 'TRENDING'
  | 'LIVE_EVENT'
  | 'EXPIRED'
  | 'PROMOTION'
  | 'NO_RIBBON'
  | 'BELOW_AVERAGE'
  | 'FAVORITE'
  | 'HIGHLY_REVIEWED'
  // | 'EARLY_PRIME_DAY'
  | 'COUPON';

const ribbonColors: Record<RibbonType, string> = {
  TRENDING: colors.trending,
  FAVORITE: colors.favorite,
  LIVE_EVENT: currentEventObj?.dealHighlightColor || colors.jdbPurple,
  SUBSCRIBE_SAVE: colors.subscribeSave,
  // EARLY_PRIME_DAY: colors.earlyEventDeal,
  COUPON: colors.coupon,
  PROMOTION: colors.extraDeal,
  BELOW_AVERAGE: colors.belowAverage,
  HIGHLY_REVIEWED: colors.highReview,
  EXPIRED: colors.expired,
  NO_RIBBON: 'transparent'
};

export const getRibbonColor = (ribbonType: RibbonType): string => {
  return ribbonColors[ribbonType];
};

export const getRibbonDisplayName = (ribbonType: RibbonType) => {
  switch (ribbonType) {
    case 'TRENDING':
      return 'Trending';
    case 'FAVORITE':
      return 'Favorite';
    case 'LIVE_EVENT':
      return `${currentEventObj?.label || ''}`;
    case 'SUBSCRIBE_SAVE':
      return 'Subscribe & Save';
    case 'COUPON':
      return 'Coupon Available';
    case 'PROMOTION':
      return 'Promotion Available';
    case 'BELOW_AVERAGE':
      return 'Lowest price';
    case 'HIGHLY_REVIEWED':
      return 'Highly reviewed';
    case 'EXPIRED':
      return 'Deal Expired';
    case 'NO_RIBBON':
    default:
      return '';
  }
};

export const renderRibbonIcon = (
  ribbonType: RibbonType,
  large: boolean = true
): React.ReactNode => {
  const props = {
    sx: large ? largeStyle : smallStyle,
    size: large ? 'small' : 'medium'
  };

  const EventIcon = currentEventObj?.Icon || CelebrationIcon;

  const icons: Record<RibbonType, React.ReactNode> = {
    LIVE_EVENT: <EventIcon {...props} />,
    SUBSCRIBE_SAVE: <AutorenewIcon {...props} />,
    COUPON: <SellIcon {...props} />,
    FAVORITE: <FavoriteIcon {...props} />,
    PROMOTION: <CampaignIcon {...props} />,
    TRENDING: <InsightsIcon {...props} />,
    BELOW_AVERAGE: <TrendingDownIcon {...props} />,
    HIGHLY_REVIEWED: <ThumbUpIcon {...props} />,
    EXPIRED: <BlockIcon {...props} />,
    NO_RIBBON: null
  };

  return icons[ribbonType];
};

export const getRibbonType = (
  dealSummary: DealPostType | null,
  currentPromotionLinks: string[],
  topDeals: DealPostType[],
  isFavorite: boolean = false,
  onlyShowImportant: boolean = false
): RibbonType => {
  const shouldDisplayLiveEvent =
    currentEventObj &&
    (dealSummary?.listingMode || '')
      .toLowerCase()
      .includes(currentEventObj?.value?.toLowerCase());

  // const shouldDisplayEarlyPrimeDay =
  //   config.IS_PRIME_RUN_UP &&
  //   (dealSummary?.listingMode || '').toLowerCase().includes('prime') &&
  //   !isPrimeDay();

  if (shouldDisplayLiveEvent) return 'LIVE_EVENT';
  // if (shouldDisplayEarlyPrimeDay) return 'EARLY_PRIME_DAY';
  if (!dealSummary?.finalPrice || !!dealSummary?.isExpired) return 'EXPIRED';
  if (isFavorite) return 'FAVORITE';

  const isTopDeal = topDeals.some(
    (topDeal) => topDeal.ASIN === dealSummary?.ASIN
  );
  if (isTopDeal) return 'TRENDING';

  if (onlyShowImportant) return 'NO_RIBBON';

  const isExtraDealLinkApproved = currentPromotionLinks.includes(
    dealSummary?.extraDealLink
  );
  if (isExtraDealLinkApproved) return 'PROMOTION';

  if (dealSummary?.averagePrice) return 'BELOW_AVERAGE';

  if (dealSummary?.reviewStars && dealSummary?.reviewStars > 4.3)
    return 'HIGHLY_REVIEWED';

  return 'NO_RIBBON';
};
