import React from 'react';
import { Typography } from '@mui/material';
import config from '@configFile';

const Disclaimer = () => {
  return (
    <Typography
      variant="caption"
      color="text.secondary"
      display="block"
      sx={{
        fontStyle: 'italic',
        fontSize: '11px',
        lineHeight: '15px',
        paddingBottom: '2px',
        color: '#767676'
      }}
    >
      {config.disclaimer}
    </Typography>
  );
};

export default Disclaimer;
