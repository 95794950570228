import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  chip: {
    marginRight: '4px !important'
  },
  couponSwitch: {
    margin: '10px'
  },
  chipSSIcon: {
    color: 'white !important',
    fontSize: '13px !important',
    marginLeft: '6px !important'
  },
  chipCouponIcon: {
    color: 'white !important',
    fontSize: '13px !important',
    marginLeft: '6px !important'
  },
  usedChipCouponIcon: {
    color: 'black !important',
    fontSize: '13px !important',
    marginLeft: '6px !important'
  },
  chipCoupon: {
    backgroundColor: '#1565c0 !important',
    color: 'white !important',
    marginTop: '0.35em !important'
  },
  usedChipCoupon: {
    backgroundColor: '#00000021 !important',
    color: 'black !important',
    marginTop: '0.35em !important'
  }
}));
